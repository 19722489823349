/* Customize the label (the container) */
.radio-container {
  display: block;
  position: relative;
  padding: 0 0 0 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radio-check {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: white;
  border: 1px solid #461E7D;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .radio-check {
  background-color: white;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .radio-check {
  background-color: white;
  border: 1px solid #461E7D;
}

.radio-container input:disabled ~ .radio-check {
  background-color: lightgray;
  border: 1px solid gray;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-check:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-check:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-check:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #461E7D;
}

.radio-label {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: 2px;
  margin-bottom: 0px;
}
