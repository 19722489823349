@import "variables";
.primary-action, .primary-action-button {
  box-shadow: none;
  padding: .6em 2.2em;
  cursor: pointer;
  background: #461E7D;
  transition: .5s;
  border-radius: 40px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  display: inline-block;
  }
.not-so-gaudy{
  padding: .1em 1em;
}
.primary-action:hover, .primary-action-button:hover {
  background: #6e46a5;
  color: white;
}

.primary-action:active, .primary-action-button:active {
  background: #6e46a5;
  color: white;
}

.primary-action:disabled, .primary-action-button:disabled {
  background: #666666;
  color: #F4F4F6;
  cursor: not-allowed;
}

.secondary-action, .secondary-action {
  box-shadow: none;
  padding: .6em 2.2em;
  cursor: pointer;
  background: white;
  transition: .5s;
  border: 1px solid #461E7D;
  border-radius: 40px;
  color: #461E7D;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  display: inline-block; }

.secondary-action:hover, .secondary-action:hover {
  background: rgb(210, 190, 255);
  color: #461E7D;
}

.secondary-action:active, .secondary-action:active {
  background: #6e46a5;
  color: #461E7D;
}

.secondary-action:disabled, .secondary-action:disabled {
  background: #666666;
  color: #F4F4F6;
  cursor: not-allowed;
}

.buttons {
  margin-top: 20px;
  margin-bottom: 5px;
}

button.primary-btn, button{
  background: var(--primary-color);
  color: white;
  border: none;
  &:hover {
    cursor: pointer;
  }
}

.link-out:after{
  font-style: normal;
  content: "\f08e";
  display: inline-block;
  position: absolute;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: $enel-text-purple;
  cursor: pointer;
}
