@use '@angular/material' as mat;
@use 'sass:map';
@use 'theme/palette';
@use 'theme/materialoverrides' as override;
@use 'theme/globalfooter';

$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$typography: mat.define-typography-config(
  $headline-1: mat.define-typography-level(112px, 112px, 300, 'RoobertENEL-Light, sans-serif', -0.0134em),
  $headline-2: mat.define-typography-level(56px, 56px, 400, 'RoobertENEL-Light, sans-serif', -0.0089em),
  $headline-3: mat.define-typography-level(45px, 48px, 400, 'RoobertENEL-Light, sans-serif', 0em),
  $headline-4: mat.define-typography-level(34px, 40px, 400, 'RoobertENEL-Light, sans-serif', 0.0074em),
  $headline-5: mat.define-typography-level(24px, 32px, 400, 'RoobertENEL-Light, sans-serif', 0em),
  $headline-6: mat.define-typography-level(20px, 32px, 500, 'RoobertENEL-Light, sans-serif', 0.0075em),
  $subtitle-1: mat.define-typography-level(16px, 28px, 400, 'RoobertENEL-Light, sans-serif', 0.0094em),
  $subtitle-2: mat.define-typography-level(15px, 24px, 500, 'RoobertENEL-Light, sans-serif', 0.0067em),
  $body-1: mat.define-typography-level(14px, 24px, 500, 'RoobertENEL-Light, sans-serif', 0.0179em),
  $body-2: mat.define-typography-level(14px, 20px, 400, 'RoobertENEL-Light, sans-serif', 0.0179em),
  $caption: mat.define-typography-level(12px, 20px, 400, 'RoobertENEL-Light, sans-serif', 0.0333em),
);

@include mat.core();

$font-config: map-merge(
  $typography,
  ('input': mat.define-typography-level(inherit, 1.125, 400, 'RoobertENEL-Light, sans-serif', 1.5px)),
  ('button': mat.define-typography-level(14px, 14px, 500, 'RoobertENEL-Light, sans-serif', 0.0893em)),
);
@include mat.all-component-typographies($font-config);

@mixin typography($theme) {
  $custom-typography-config: mat.get-typography-config($theme);

  .mat-mdc-form-field {
    @include mat.typography-level($custom-typography-config, 'input')
  }

  .mat-button, .mat-raised-button, .mat-flat-button, .mat-mdc-unelevated-button, .mat-icon-button, .mat-stroked-button, .mat-fab, .mat-mini-fab {
    @include mat.typography-level($custom-typography-config, 'button')
  }
}

.main-container {
  --text-primary-color: #{white};
  --text-primary-lighter-color: #{rgba(black, 0.87)};
  --text-primary-darker-color: #{white};

  --text-accent-color: #{white};
  --text-accent-lighter-color: #{rgba(black, 0.87)};
  --text-accent-darker-color: #{white};

  --text-warn-color: #{white};
  --text-warn-lighter-color: #{rgba(black, 0.87)};
  --text-warn-darker-color: #{white};
}

$primary-theme: mat.define-light-theme((
  color: map.merge(
    (
      primary: palette.$primary-palette,
      accent: palette.$primary-palette,
      warn: palette.$warning-palette,
    ), (
      'background': palette.$light-theme-background-palette,
      'foreground': palette.$light-theme-foreground-palette,
    )
  ),
  typography: $font-config,
  density: -1
));

html {
  @include override.angular-material-overrides($primary-theme);
  @include globalfooter.footer-overrides($primary-theme);
  @include mat.core-theme($primary-theme);
  @include mat.all-component-colors($primary-theme)
}