@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

.dtp-button {
  border-bottom-right-radius: .25rem;
  border-top-right-radius: .25rem;
  padding: 8.5px 13px;
  margin-left: -1px;
  border: 1px solid #ced4da;
  background-color: #461E7D;
  background-clip: padding-box;
  line-height: 1.5;
  cursor: pointer;
  color: #fff;
}

.dtp-button:hover {
  background-color: #6e46a5;
  transition: .5s;
}

.dtp-input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  width: auto;
  display: inline-block;
}

.dtp-input:focus {
  outline: none !important;
}

div:focus {
  outline: none;
}