@mixin footer-overrides($theme) {

  button.feedback-button.mat-mdc-outlined-button:not(:disabled) {
    border-color: rgb(70, 30, 125);
    color: rgb(70, 30, 125);
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
    font-family: Roobert, RoobertENEL-Light, sans-serif;
    letter-spacing: 0px;
    outline-color: rgb(70, 30, 125);

    mat-icon.mat-icon {
      transform: scale(1.5);
      padding-top: 2px;
      padding-left: 1px;
    }
  }

}