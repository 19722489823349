@import "./variables";

.table, .p-datatable {
  margin: 2% 0;
  border: solid #D4D4D4;
  border-width: 1px;
  td {
    vertical-align: middle !important;
  }
}

.p-datatable table {
  table-layout: auto !important;
}

.table, .p-datatable thead tr th {
  padding: 10px;
}

.table, .p-datatable tr {
  height: 45px;
  padding-left: 30px;
}
.table, .p-datatable tr:nth-child(even) {
  background-color: #f5f4fb;
}

.table, .p-datatable thead {
  background-color: white;
  border: solid #D4D4D4;
  border-width: 1px 0;
  color: #717171
}

.slim-table, .p-datatable td {
  padding: .3rem !important;
}

.sortableHeader {
  cursor: pointer;
}

.timezone-conversion-table {
  border: 1px solid gray;
  border-radius: 5px  !important;
  margin: 10px 20px;
  background-color: lightgoldenrodyellow;
  color: $enel-gray;

}

.reg-limit-table {
  empty-cells: hide;
  text-align: center;
  margin: 0 auto;
  width: auto;
  td:not(.header-cell){
    border-right: 1px dashed $enel-gray;;
  }
}

.header-cell {
  border-right: 1px solid $enel-gray;
  text-align: left !important;
}

.table-title {
  text-align: center;
  width: 100%;
  margin: 15px 0 5px 0;
  color: #717171;
}
