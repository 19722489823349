$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/brands";
@import "checkbox";
@import "radiobutton";
@import "button";
@import "table";
@import "ptable";
@import 'toaster';
@import 'datetimepicker';
@import "variables";
@import 'primeng/resources/primeng.min.css';
@import 'primeng/resources/components/panel/panel.css';
@import 'primeng/resources/components/paginator/paginator.css';
@import 'primeng/resources/components/scrollpanel/scrollpanel.css';
@import 'primeng/resources/components/table/table.css';
@import "primeng/resources/themes/saga-blue/theme.css";

@font-face {
  font-family: "RoobertENEL-Bold";
  src: url("./assets/fonts/RoobertENEL-Bold.woff2");
}

@font-face {
  font-family: "RoobertENEL";
  src: url("./assets/fonts/RoobertENEL-Regular.woff2");
}

@font-face {
  font-family: "RoobertENEL-Light";
  src: url("./assets/fonts/RoobertENEL-Light.woff2");
}


body,
html {
  height: 100%;
}

body {
  font-family: RoobertENEL-Light, sans-serif;
  background-color: #f6f6f6;
  font-size: 14px;
}

label {
  margin-bottom: 0;
}

h1 {
  color: #461e7d;
  font-family: RoobertENEL-Light, sans-serif;
  font-size: 21px;
}

h2 {
  color: #333333;
  font-family: RoobertENEL-Light, sans-serif;
  font-size: 18px;
}

h3 {
  font-family: RoobertENEL-Light, sans-serif;
}

h4 {
  font-family: RoobertENEL-Light, sans-serif;
}

.global-footer {
  margin-top: 20px;
}

.ovh {
  overflow: hidden;
}

.verticalFlexWrapper {
  display: inline-block;
}

.webpage {
  padding: 2%;
}

.centered {
  text-align: center;
}

.parentFlex {
  display: flex;
  flex-direction: column;
}

.form-field {
  margin: 15px 5px;
}

.header-bar {
  display: block;
  box-shadow: rgba(156, 156, 156, .5) 1px 1px 4px;
  padding: 10px 5px;
  margin: 15px 0;
}

.card {

  background-color: white;
  display: inline-block;
  box-shadow: rgba(156, 156, 156, .5) 1px 1px 4px;
  margin-bottom: 15px;
}

.no-shadow {
  box-shadow: none;
}

.no-border {
  border: none;
}

.card-details {
  border-top: 1px solid #6e46a5;
  padding: 0px 20px 20px 20px;
  display: inline-block;
  min-width: 725px;
  margin: 20px;
}

.exception-text {
  font-weight: bolder;
  color: $enel-red;
  font-size: 16px;
}

.success-text {
  font-weight: bolder;
  color: $enel-green;
  font-size: 16px;
}

.top-margin {
  margin-top: 20px;
}

legend {
  padding: 0.2em 0.5em;
  font-size: 90%;
  width: auto;
  //font-family: Avenir, sans-serif;
  color: #717171;
}

.input-label {
  display: block;
  //font-family: Avenir, sans-serif;
  font-size: 14px;
  color: #717171;
}

.filter-bar-label {
  display: block;
  //font-family: Avenir, sans-serif;
  font-size: 14px;
  color: #717171;
  border-bottom: #461E7D solid 1px;
  margin-bottom: 10px;
}

.filter-bar {
  width: max-content;
  margin-bottom: 15px;
  border: 1px solid #717171;
  padding: 10px;
}

.filter-container {
  display: inline-block;
  margin-right: 15px;
}

.filter-label {
  display: block;
  //font-family: Avenir, sans-serif;
  font-size: 14px;
  color: #461e7d;
}

.summary-label {
  color: #717171;
  margin: 5px 15px 5px 0;
  font-size: 14px;
}

.summary-section {
  display: inline-flex;
  flex-direction: column;
  padding: 0 25px;
  text-align: left;
  flex: 2;
}

.inline {
  display: inline-block;
  margin-right: 15px;
}

// Custom select display
.select-wrapper {
  position: relative;
  display: inline-block;

  font-size: 14px;
  color: #333333;

  .select-input {
    -webkit-appearance: none;
    border: 1px solid #D4D4D4;
    background-color: #fff;
    border-radius: 0;
    padding-right: 36px;
    margin-top: 6px;
    padding-left: 15px;
    font-size: 14px;
    width: 100%;
    line-height: 36px;

    &::-ms-expand {
      display: none;
    }

    &:disabled {
      color: graytext;
    }
  }

  &:after {
    content: "\f078";
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 15px;
    pointer-events: none;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #333333;

  }
}

.form-element {
  line-height: 36px;
  border: 1px solid #D4D4D4;
  padding-left: 15px;
}

input:disabled {
  background-color: #e9ecef;
}

.minutes-input {
  display: block;
  width: 100%;
}

.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
  -moz-appearance: textfield !important;
}

.error-messages {
  font-size: 12px;
  color: #b0003a;
}

.primary-link {
  font-size: 14px;
  font-weight: bold;
  color: #461e7d;
  margin-bottom: 10px;
  cursor: pointer;
}

.left {
  float: left;
}

.right {
  float: right;
}

button:focus {
  outline: 0;
}

.loading-text {
  h2 {
    color: #461e7d;
  }

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(248, 248, 255, .5);
  padding-top: 25px;
}

.events-container {
  position: relative;
}

.clear {
  clear: both;
}

.tooltip-icon {
  margin-left: 5px;
  color: $enel-text-purple;
  cursor: pointer;
}

.tooltip-icon .tooltip-text {
  //font-family: Avenir, sans-serif;
  visibility: hidden;
  background-color: white;
  color: $enel-text-purple;
  box-shadow: rgba(156, 156, 156, 0.5) 9px 4px 4px;
  border-radius: 6px;
  padding: 10px;
  border: 2px solid;

  position: absolute;
  z-index: 1;
  margin-left: 15px;
  margin-top: -32px;
}

.tooltip-icon:hover .tooltip-text {
  visibility: visible;
}

.timestamp-tooltip {
  .tooltip-inner {
    white-space: nowrap;
    display: inline-block;
    //font-family: Avenir, sans-serif;
    background-color: white;
    color: $enel-text-purple;
    box-shadow: rgba(156, 156, 156, 0.5) 9px 4px 4px;
    border-radius: 6px;
    border: 2px $enel-text-purple solid;
    margin-left: -15px;
    max-width: fit-content;
    text-align: left;
  }

  .timestamp-tooltip .arrow::before {
    border-top-color: $enel-text-purple;
  }
}

.event-node-buttons-wrapper {
  float: right;
  position: absolute;
  right: 0;
  bottom: 0;
}

.event-node-search-wrapper {
  margin-top: 35px;
  overflow: auto;
  position: relative;
}

.table-row-green-no-border {
  color: rgb(89, 188, 95);
  font-weight: bolder;
}

.table-row-yellow-no-border {
  color: rgb(254, 204, 50);
  font-weight: bolder;
}

.table-row-opted-out-no-border {
  color: rgba(113, 113, 113, .5);
  font-weight: bolder;
}

.table-row-green {
  color: rgb(89, 188, 95);
  font-weight: bolder;

  -webkit-box-shadow: inset 3px 0 0 0 rgb(89, 188, 95);
  -moz-box-shadow: inset 3px 0 0 0 rgb(89, 188, 95);
  box-shadow: inset 3px 0 0 0 rgb(89, 188, 95);
}

.table-row-yellow {
  color: rgb(254, 204, 50);
  font-weight: bolder;
  -webkit-box-shadow: inset 3px 0 0 0 rgb(254, 204, 50);
  -moz-box-shadow: inset 3px 0 0 0 rgb(254, 204, 50);
  box-shadow: inset 3px 0 0 0 rgb(254, 204, 50);
}

.table-row-opted-out {
  color: rgba(113, 113, 113, .5);
  font-weight: bolder;
  -webkit-box-shadow: inset 3px 0 0 0 rgba(113, 113, 113, .5);
  -moz-box-shadow: inset 3px 0 0 0 rgba(113, 113, 113, .5);
  box-shadow: inset 3px 0 0 0 rgba(113, 113, 113, .5);
}

.table-row-completed {
  color: $enel-text-purple;
  font-weight: bolder;
  -webkit-box-shadow: inset 3px 0 0 0 $enel-text-purple;
  -moz-box-shadow: inset 3px 0 0 0 $enel-text-purple;
  box-shadow: inset 3px 0 0 0 $enel-text-purple;
}

.timeline {
  .label {
    z-index: 1 !important;
  }

  .highcharts-tooltip span {
    background-color: #f8f8f8;
    border: 1px solid $enel-text-purple;
    padding: 5px;
    border-radius: 5px;
    opacity: 1;
    z-index: 9999 !important;
  }

  .tooltip {
    padding: 5px;
  }


}

.section-panel {
  margin-bottom: 15px;
  box-shadow: rgba(156, 156, 156, 0.5) 1px 1px 4px;

  .panel-title-center {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-left: 22px;
  }

  .p-panel .p-panel-header {
    background-color: #fff;
    cursor: pointer;
    padding-top: 0;
    padding-bottom: 0;
  }

  .p-panel-expanded {
    .p-panel-header {
      border-bottom: none;
    }
  }
}

/* icons */
.fa,
.fas {
  font-weight: 900;
}
