@use '@angular/material' as mat;

@mixin angular-material-overrides($theme) {
  $primary-theme: map-get($theme, primary);

  .mat-mdc-header-cell {
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54);
  }

  .mdc-data-table__table {
    min-width: auto !important;
  }
  
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    padding: 24px;
  }
}